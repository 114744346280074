import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCustomersList() {
  // Use toast
  const toast = useToast()

  const refCustomerListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: false, label:'CUSTOMER' },
    { key: 'document', sortable: false },
    { key: 'email', sortable: false },
    { key: 'manager', sortable: false },
    { key: 'managerEmail', sortable: false },
    { key: 'managerPhone', sortable: false },
    //{ key: 'contact', sortable: true },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    //{ key: 'status', sortable: true },
    { key: 'actions' },
  ]
  /*
  "id": "ebcdb5ce-a89c-454c-b82e-59b17b8c2608",
      "name": "Test Consumer Company",
      "document": "000.000.000/0000-00",
      "ownerName": "Test Tracker Company"
  */


  const perPage = ref(10)
  const totalCustomers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  //const roleFilter = ref(null)
  //const planFilter = ref(null)
  //const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerListTable.value ? refCustomerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomers.value,
    }
  })

  const refetchData = () => {
    refCustomerListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, ], () => {
    refetchData()
  })

  const fetchCustomers = (ctx, callback) => {
    store
      .dispatch('app-customer/fetchCustomers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        //role: roleFilter.value,
        //plan: planFilter.value,
        //status: statusFilter.value,
      })
      .then(response => {
        //const { customers, total } = response.data

        const customers = response.data.data;
        const total = response.data.length;

        callback(customers)
        totalCustomers.value = response.data.totalItems
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching customers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCustomerRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveCustomerRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveCustomerStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchCustomers,
    tableColumns,
    perPage,
    currentPage,
    totalCustomers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerListTable,

    resolveCustomerRoleVariant,
    resolveCustomerRoleIcon,
    resolveCustomerStatusVariant,
    refetchData,

    // Extra Filters
    //roleFilter,
    //planFilter,
    //statusFilter,
  }
}
